import React from "react"
import { css } from "@emotion/react"
import Seo from "~components/Seo"
import Section from "~components/Section"
import { breakpoints } from '~styles/global'
import axios from "axios"

const Test = () => {

  const categoryData = {
    id: 4975484786743,
    name: 'AFOMD',
    user_id: 28,
    sales_xero_account_id: null,
    purchase_xero_account_id: null,
    team_id: 1,
    deleted_at: 'yes',
    created_at: '2021-07-06 14:21:30',
    updated_at: '2021-07-06 14:21:37'
  }

  const brandData = {
    id: 15,
    team_id: 1,
    name: 'AFOM',
    code: 'AFOMD',
    user_id: 28,
    deleted_at: '2021-07-05 11:55:02',
    created_at: '2021-07-05 11:55:02',
    updated_at: '2021-07-06 13:08:51'
  }

  const productDataTest = {
  title: 'Jack Test Product Unpurch',
  id: 'unpurchasable',
  thumbnail: 'https://d3vsvyv7mlffbf.cloudfront.net/images/3DZginuRBiznhXo0mG74t5WrJPHJfhNmmEtUZRA4.jpeg',
  isPurchasable: true,
  isActive: true,
  shopifyProductID: 68894454580853333,
  variants: [
    {
      shopifyVariantID: 'fhjdgh',
      variant_id: 29,
      variant_sku: 0,
      model: null,
      price: 10,
      volume: null,
      colour: null,
      size: null,
      finish: 'Xglass matt calacatta black',
      dimensions: '184cm x 40cm x 75cm',
      top: null,
      shape: null,
      upholstery: null,
      frame: 'Black',
      stitching: null,
      option: null,
      doors: null,
      mattress: null,
      base: null,
      seat: null,
      seatHeight: null,
      structure: null,
      fronts: null,
      sides: null,
      lightSource: null,
      material: null
    },
  ]
}

const productData = {
  title: '36e8 sideboard',
  id: 23,
  thumbnail: 'https://d3vsvyv7mlffbf.cloudfront.net/images/XAtAkt94TiVmJC6kawnc6NhWEHn6hqcbCS9rGM3r.jpeg',
  isPurchasable: false,
  isVisible: true,
  shopifyProductID: 6951911424165,
  defaultVariant: 29,
  created_at: '2019-08-19T23:17:54.000000Z',
  updated_at: '2021-08-03T00:12:53.000000Z',
  deleted_at: null,
  categories: [ { id: 15, title: 'SIDEBOARDS' } ],
  brand: { id: 1, title: 'Lago' },
  variants: [
    {
      shopifyVariantID: 0,
      variant_id: 29,
      variant_sku: 0,
      model: null,
      price: 0,
      volume: null,
      colour: null,
      size: null,
      finish: 'Xglass matt calacatta black',
      dimensions: '184cm x 40cm x 75cm',
      top: null,
      shape: null,
      upholstery: null,
      frame: 'Black',
      stitching: null,
      option: null,
      doors: null,
      mattress: null,
      base: null,
      seat: null,
      seatHeight: null,
      structure: null,
      fronts: null,
      sides: null,
      lightSource: null,
      material: null
    },
    {
      shopifyVariantID: 0,
      variant_id: 187,
      variant_sku: 36,
      model: null,
      price: 0,
      volume: null,
      colour: null,
      size: null,
      finish: 'Xglass matt calacatta black',
      dimensions: '184cm x 40cm x 75cm',
      top: null,
      shape: null,
      upholstery: null,
      frame: 'Black',
      stitching: null,
      option: null,
      doors: null,
      mattress: null,
      base: null,
      seat: null,
      seatHeight: null,
      structure: null,
      fronts: null,
      sides: null,
      lightSource: null,
      material: null
    },
    {
      shopifyVariantID: 40495258730661,
      variant_id: 345,
      variant_sku: 368,
      model: null,
      price: 7195,
      volume: null,
      colour: null,
      size: null,
      finish: null,
      dimensions: '184cm(W) x 40cm(D) x 75cm(H)',
      top: null,
      shape: null,
      upholstery: null,
      frame: 'Black',
      stitching: null,
      option: null,
      doors: 'Xglass matt calacatta black',
      mattress: null,
      base: 'Glass legs',
      seat: null,
      seatHeight: null,
      structure: null,
      fronts: null,
      sides: null,
      lightSource: null,
      material: null
    },
    {
      shopifyVariantID: 40495258763429,
      variant_id: 511,
      variant_sku: 368,
      model: '0580',
      price: 0,
      volume: null,
      colour: null,
      size: null,
      finish: 'Matt Black lacquer',
      dimensions: '220cm(W) x 40cm(D) x 75cm(H)',
      top: null,
      shape: null,
      upholstery: null,
      frame: null,
      stitching: null,
      option: null,
      doors: null,
      mattress: null,
      base: 'Glass legs',
      seat: null,
      seatHeight: null,
      structure: null,
      fronts: null,
      sides: null,
      lightSource: null,
      material: null
    },
    {
      shopifyVariantID: 40495258796197,
      variant_id: 513,
      variant_sku: 368,
      model: '0580',
      price: 0,
      volume: null,
      colour: null,
      size: null,
      finish: null,
      dimensions: '147.2cm(W) x 40.6cm(D) x 75cm(H)',
      top: null,
      shape: null,
      upholstery: null,
      frame: null,
      stitching: null,
      option: null,
      doors: null,
      mattress: null,
      base: 'Extra clear glass legs',
      seat: null,
      seatHeight: null,
      structure: 'Bianco lacquer',
      fronts: 'Bianco lacquer',
      sides: null,
      lightSource: null,
      material: null
    },
    {
      shopifyVariantID: 40495258828965,
      variant_id: 1204,
      variant_sku: 368,
      model: null,
      price: 0,
      volume: null,
      colour: null,
      size: null,
      finish: null,
      dimensions: '257cm(W) x 56cm(D) x 75cm(H)',
      top: null,
      shape: null,
      upholstery: null,
      frame: 'Mandorla',
      stitching: null,
      option: null,
      doors: 'Bronzo polished Xglass',
      mattress: null,
      base: 'Clear glass',
      seat: null,
      seatHeight: null,
      structure: null,
      fronts: null,
      sides: null,
      lightSource: null,
      material: null
    },
    {
      shopifyVariantID: 40495258861733,
      variant_id: 1503,
      variant_sku: 368,
      model: '05801',
      price: 0,
      volume: null,
      colour: 'Olivia',
      size: null,
      finish: 'Lacquer',
      dimensions: '147.2cm(W) x 40.6cm(D) x 75.3cm(H)',
      top: null,
      shape: null,
      upholstery: null,
      frame: null,
      stitching: null,
      option: null,
      doors: 'Polished glass Olivia',
      mattress: null,
      base: null,
      seat: null,
      seatHeight: null,
      structure: null,
      fronts: null,
      sides: null,
      lightSource: null,
      material: null
    },
    {
      shopifyVariantID: 40495258894501,
      variant_id: 1504,
      variant_sku: 368,
      model: '05801',
      price: 0,
      volume: null,
      colour: 'Olivia',
      size: null,
      finish: 'Matt Lacquer',
      dimensions: '184cm(W) x 40.6cm(D) x 75.3cm(H)',
      top: null,
      shape: null,
      upholstery: null,
      frame: null,
      stitching: null,
      option: null,
      doors: 'Olivia Polished Glass',
      mattress: null,
      base: 'Extra clear tempered glass',
      seat: null,
      seatHeight: null,
      structure: null,
      fronts: null,
      sides: null,
      lightSource: null,
      material: null
    },
    {
      shopifyVariantID: 40495258927269,
      variant_id: 1573,
      variant_sku: 368,
      model: '05801',
      price: 4295,
      volume: null,
      colour: 'Tortora',
      size: null,
      finish: 'Lacquered',
      dimensions: '147cm(W) X 40cm(D) X 75cm(H)',
      top: null,
      shape: null,
      upholstery: null,
      frame: null,
      stitching: null,
      option: null,
      doors: null,
      mattress: null,
      base: 'Glass Legs',
      seat: null,
      seatHeight: null,
      structure: null,
      fronts: null,
      sides: null,
      lightSource: null,
      material: null
    },
    {
      shopifyVariantID: 40495258960037,
      variant_id: 1785,
      variant_sku: 368,
      model: '0532',
      price: 0,
      volume: null,
      colour: 'Tortora',
      size: null,
      finish: 'Lacquered',
      dimensions: '184cm(W) X 40.6cm(D) X 75.3cm(H)',
      top: null,
      shape: null,
      upholstery: null,
      frame: null,
      stitching: null,
      option: null,
      doors: 'Matt glass front',
      mattress: null,
      base: 'Glass Legs',
      seat: null,
      seatHeight: null,
      structure: null,
      fronts: null,
      sides: null,
      lightSource: null,
      material: null
    },
    {
      shopifyVariantID: 40495258992805,
      variant_id: 1958,
      variant_sku: 368,
      model: '05802',
      price: 0,
      volume: null,
      colour: null,
      size: null,
      finish: 'Lacquer',
      dimensions: '147cm(W) X 40cm(D) X 75cm(H)',
      top: null,
      shape: null,
      upholstery: null,
      frame: null,
      stitching: null,
      option: null,
      doors: 'Polished Glass',
      mattress: null,
      base: null,
      seat: null,
      seatHeight: null,
      structure: null,
      fronts: null,
      sides: null,
      lightSource: null,
      material: null
    },
    {
      shopifyVariantID: 40495259025573,
      variant_id: 2088,
      variant_sku: 368,
      model: '05801',
      price: 0,
      volume: null,
      colour: 'Mandorla',
      size: null,
      finish: 'Matt Lacquer',
      dimensions: '147.2cm(W) X 40.6cm(D) X 75.3cm(H)',
      top: null,
      shape: null,
      upholstery: null,
      frame: null,
      stitching: null,
      option: null,
      doors: 'Mandorla Polished Glass',
      mattress: null,
      base: 'Extra clear tempered glass',
      seat: null,
      seatHeight: null,
      structure: null,
      fronts: null,
      sides: null,
      lightSource: null,
      material: null
    },
    {
      shopifyVariantID: 40495259058341,
      variant_id: 2267,
      variant_sku: 368,
      model: '05802',
      price: 5095,
      volume: null,
      colour: null,
      size: null,
      finish: null,
      dimensions: '184cm(L) x 40.6cm(D) x 75.3cm(H)',
      top: null,
      shape: null,
      upholstery: null,
      frame: 'Matt lacquer Oliva',
      stitching: null,
      option: null,
      doors: 'Polished glass Oliva',
      mattress: null,
      base: null,
      seat: null,
      seatHeight: null,
      structure: null,
      fronts: null,
      sides: null,
      lightSource: null,
      material: null
    },
    {
      shopifyVariantID: 40495259091109,
      variant_id: 2363,
      variant_sku: 368,
      model: '05801',
      price: 0,
      volume: null,
      colour: 'Iuta',
      size: null,
      finish: null,
      dimensions: '220cm(W) x 40cm(D) x 75cm(H)',
      top: null,
      shape: null,
      upholstery: null,
      frame: null,
      stitching: null,
      option: null,
      doors: 'Iuta Polished Glass',
      mattress: null,
      base: 'Extra clear tempered glass',
      seat: null,
      seatHeight: null,
      structure: null,
      fronts: null,
      sides: null,
      lightSource: null,
      material: null
    },
    {
      shopifyVariantID: 40495259123877,
      variant_id: 2482,
      variant_sku: 368,
      model: null,
      price: 0,
      volume: null,
      colour: 'Left unit in Wildwood Grigio, top unit in Sole polished glass, bottom unit in Mandorla polished glass',
      size: null,
      finish: null,
      dimensions: '147cm(W) X 56cm(D) X 75cm(H)',
      top: null,
      shape: null,
      upholstery: null,
      frame: null,
      stitching: null,
      option: null,
      doors: null,
      mattress: null,
      base: null,
      seat: null,
      seatHeight: null,
      structure: null,
      fronts: null,
      sides: null,
      lightSource: null,
      material: null
    },
    {
      shopifyVariantID: 40495259156645,
      variant_id: 2558,
      variant_sku: 368,
      model: '05802',
      price: 0,
      volume: null,
      colour: null,
      size: null,
      finish: null,
      dimensions: '147.2cm(W) X 40.6cm(D) X 75.3cm(H)',
      top: 'Havana lacquer',
      shape: null,
      upholstery: null,
      frame: 'Mandorla lacquer',
      stitching: null,
      option: 'Large door on the left',
      doors: 'Left: polished glass Havana, centre: polished glass Tortora, right: polished glass Argilla',
      mattress: null,
      base: 'Extra clear glass legs',
      seat: null,
      seatHeight: null,
      structure: null,
      fronts: null,
      sides: null,
      lightSource: null,
      material: null
    },
    {
      shopifyVariantID: 40495259189413,
      variant_id: 2631,
      variant_sku: 368,
      model: '11008',
      price: 0,
      volume: null,
      colour: null,
      size: null,
      finish: null,
      dimensions: '147.2cm(W) x 56.0cm(D)x 75.3cm(H)',
      top: null,
      shape: null,
      upholstery: null,
      frame: 'Iuta Lacquered',
      stitching: null,
      option: null,
      doors: 'Iuta Matt Glass',
      mattress: null,
      base: null,
      seat: null,
      seatHeight: null,
      structure: null,
      fronts: null,
      sides: null,
      lightSource: null,
      material: null
    },
    {
      shopifyVariantID: 40495259222181,
      variant_id: 2754,
      variant_sku: 368,
      model: '05801',
      price: 0,
      volume: null,
      colour: null,
      size: null,
      finish: null,
      dimensions: '184cm(W) X 40cm(D) X 75cm(H)',
      top: null,
      shape: null,
      upholstery: null,
      frame: 'Matt bianco',
      stitching: null,
      option: null,
      doors: 'Matt bianco glass',
      mattress: null,
      base: 'Glass legs',
      seat: null,
      seatHeight: null,
      structure: null,
      fronts: null,
      sides: null,
      lightSource: null,
      material: null
    },
    {
      shopifyVariantID: 40495259254949,
      variant_id: 2790,
      variant_sku: 368,
      model: '0700',
      price: 0,
      volume: null,
      colour: null,
      size: null,
      finish: null,
      dimensions: '147.2cm(W) X 40.6cm(D) X 75.3cm(H)',
      top: null,
      shape: null,
      upholstery: null,
      frame: 'Matt bianco',
      stitching: null,
      option: null,
      doors: 'Xglass polishes statuario',
      mattress: null,
      base: null,
      seat: null,
      seatHeight: null,
      structure: null,
      fronts: null,
      sides: null,
      lightSource: null,
      material: null
    },
    {
      shopifyVariantID: 40495259287717,
      variant_id: 3044,
      variant_sku: 368,
      model: '0474',
      price: 0,
      volume: null,
      colour: null,
      size: null,
      finish: null,
      dimensions: ': 92cm(W) x 40.6cm(D) x 130.5cm(H)',
      top: null,
      shape: null,
      upholstery: null,
      frame: 'Top: Bianco lacquer - Bottom right: Fango lacquer - Bottom left: Blu scuro lacquer',
      stitching: null,
      option: null,
      doors: 'Top: Matt Gold Calacatta X glass - Bottom right: Fango matt glass - Bottom left: Blu scuro matt glass',
      mattress: null,
      base: null,
      seat: null,
      seatHeight: null,
      structure: null,
      fronts: null,
      sides: null,
      lightSource: null,
      material: null
    },
    {
      shopifyVariantID: 40495259320485,
      variant_id: 3062,
      variant_sku: 368,
      model: null,
      price: 0,
      volume: null,
      colour: null,
      size: null,
      finish: null,
      dimensions: '147.2cm(W) X 40.6cm(D) X 75.3cm(H)',
      top: null,
      shape: null,
      upholstery: null,
      frame: 'Matt Black',
      stitching: null,
      option: null,
      doors: 'Xglass matt calacatta black',
      mattress: null,
      base: null,
      seat: null,
      seatHeight: null,
      structure: null,
      fronts: null,
      sides: null,
      lightSource: null,
      material: null
    },
    {
      shopifyVariantID: 40495259353253,
      variant_id: 3101,
      variant_sku: 368,
      model: null,
      price: 0,
      volume: null,
      colour: null,
      size: null,
      finish: null,
      dimensions: '147.2cm(W) X 40.6cm(D) X 75.3cm(H)',
      top: null,
      shape: null,
      upholstery: null,
      frame: 'Bianco',
      stitching: null,
      option: null,
      doors: 'Xglass Matt Calacatta gold',
      mattress: null,
      base: 'Glass legs',
      seat: null,
      seatHeight: null,
      structure: null,
      fronts: null,
      sides: null,
      lightSource: null,
      material: null
    },
    {
      shopifyVariantID: 40495259386021,
      variant_id: 3102,
      variant_sku: 368,
      model: null,
      price: 0,
      volume: null,
      colour: null,
      size: null,
      finish: null,
      dimensions: '147.2cm(W) X 40.6cm(D) X 75.3cm(H)',
      top: null,
      shape: null,
      upholstery: null,
      frame: 'Bianco',
      stitching: null,
      option: null,
      doors: 'Xglass matt calacatta gold',
      mattress: null,
      base: null,
      seat: null,
      seatHeight: null,
      structure: null,
      fronts: null,
      sides: null,
      lightSource: null,
      material: null
    },
    {
      shopifyVariantID: 40495259418789,
      variant_id: 3149,
      variant_sku: 368,
      model: '0700',
      price: 7195,
      volume: null,
      colour: null,
      size: null,
      finish: null,
      dimensions: '220.8cm(W) X 40.6cm(D) X 75cm(H)',
      top: null,
      shape: null,
      upholstery: null,
      frame: 'Matt bianco',
      stitching: null,
      option: null,
      doors: 'Xglass Matt Statuario',
      mattress: null,
      base: null,
      seat: null,
      seatHeight: null,
      structure: null,
      fronts: null,
      sides: null,
      lightSource: null,
      material: null
    },
    {
      shopifyVariantID: 40495259451557,
      variant_id: 3409,
      variant_sku: 368,
      model: '08004',
      price: 0,
      volume: null,
      colour: null,
      size: null,
      finish: null,
      dimensions: '184cm(L) x 40.6cm(D) x 75.3cm(H)',
      top: null,
      shape: null,
      upholstery: null,
      frame: 'Matt lacquer Oliva',
      stitching: null,
      option: '3 x drawer units 1 x large door',
      doors: 'Polished glass Oliva',
      mattress: null,
      base: null,
      seat: null,
      seatHeight: null,
      structure: null,
      fronts: null,
      sides: null,
      lightSource: null,
      material: null
    },
    {
      shopifyVariantID: 40495259484325,
      variant_id: 3448,
      variant_sku: 368,
      model: null,
      price: 0,
      volume: null,
      colour: null,
      size: null,
      finish: null,
      dimensions: '110.4cm(L) x 40.6cm(D) x 75.5cm(H)',
      top: null,
      shape: null,
      upholstery: null,
      frame: 'Matt lacquer Oliva',
      stitching: null,
      option: 'Small door on left: 36.8cm(W) - Large door on right: 73.6cm(W)',
      doors: 'Polished glass Oliva',
      mattress: null,
      base: null,
      seat: null,
      seatHeight: null,
      structure: null,
      fronts: null,
      sides: null,
      lightSource: null,
      material: null
    },
    {
      shopifyVariantID: 40495259517093,
      variant_id: 3586,
      variant_sku: 368,
      model: '11008',
      price: 0,
      volume: null,
      colour: null,
      size: null,
      finish: null,
      dimensions: '184.0cm(L) x 56.0cm(D) x 75.3cm(H)',
      top: null,
      shape: null,
      upholstery: null,
      frame: 'Tortora matt lacquer',
      stitching: null,
      option: null,
      doors: 'Tortora polished glass',
      mattress: null,
      base: 'Extra clear tempered glass legs',
      seat: null,
      seatHeight: null,
      structure: null,
      fronts: null,
      sides: null,
      lightSource: null,
      material: null
    },
    {
      shopifyVariantID: 40495259549861,
      variant_id: 3722,
      variant_sku: 368,
      model: '05802',
      price: 0,
      volume: null,
      colour: null,
      size: null,
      finish: 'Cipria Lacquer',
      dimensions: '147cm(W) X 56cm(D) X 75cm(H)',
      top: null,
      shape: null,
      upholstery: null,
      frame: null,
      stitching: null,
      option: null,
      doors: 'Cipria Lacquer',
      mattress: null,
      base: null,
      seat: null,
      seatHeight: null,
      structure: null,
      fronts: null,
      sides: null,
      lightSource: null,
      material: null
    },
    {
      shopifyVariantID: 40495259582629,
      variant_id: 3723,
      variant_sku: 368,
      model: '05802',
      price: 0,
      volume: null,
      colour: null,
      size: null,
      finish: 'Cipria Lacquer',
      dimensions: '184cm(W) X 56cm(D) X 75cm(H)',
      top: null,
      shape: null,
      upholstery: null,
      frame: null,
      stitching: null,
      option: null,
      doors: 'Cipria Lacquer',
      mattress: null,
      base: null,
      seat: null,
      seatHeight: null,
      structure: null,
      fronts: null,
      sides: null,
      lightSource: null,
      material: null
    },
    {
      shopifyVariantID: 40495259615397,
      variant_id: 3750,
      variant_sku: 368,
      model: '09913',
      price: 0,
      volume: null,
      colour: null,
      size: null,
      finish: null,
      dimensions: '220.8cm(W) x 40.6cm(D) x 83.3cm(H)',
      top: 'Wildwood Scuro shelf',
      shape: null,
      upholstery: null,
      frame: 'Matt lacquer Ocra Avorio',
      stitching: null,
      option: null,
      doors: null,
      mattress: null,
      base: 'Extra Clear Tempered Glass Legs',
      seat: null,
      seatHeight: null,
      structure: null,
      fronts: 'Matt glass Ocra Avorio',
      sides: null,
      lightSource: null,
      material: null
    }
  ]
}

  const sendTest = () => {
    axios({
      url: '/.netlify/functions/product-sync',
      method: 'post',
      data: productDataTest
    }).then(result => {
      console.log(result)
      return null
    })
    .catch(error => {
      console.log(error)
    })
    return false
  }

  return (
    <Section>
      <button onClick={() => sendTest()}>Send Test</button>
    </Section>
  )
}

export default Test
